<template>
  <v-container :class="'grey ' + isAlternate ? 'lighten-2' : 'lighten-3'">
    <v-row class="mh-main-row">
      <v-col cols="12" class="pt-0 mh-main-col">
        <main-filters
          :reportRequest="reportRequest"
          :page="pageData"
          :aggregateVisible="true"
          :loading="filter_loading"
          :show-hourly="false"
          @cubeDataClick="cubeData"
          @graphType="graphTypeClick"
          @calendarRange="setRange"
          @cities="setCities"
          @stopCalc="stopCube"
        >
        </main-filters>
        <v-card
          outlined
          class="mt-2"
          v-if="chart_loading === false"
          id="highcharts_png"
        >
          <v-card-title
            class="text-lg-h5 text-md-h6 justify-space-between pb-0 flex-row"
          >
            {{ chartTittle }}
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-btn-toggle
              borderless
              v-model="toggle_graphType"
              mandatory
              dense
              v-if="this.indicator !== 'All' && this.aggregate === 'None'"
            >
              <v-btn
                elevation="0"
                class="mh-noactive-toggle"
                :active-class="'white black--text mh-active-toggle'"
              >
                <v-icon>mdi-chart-line</v-icon>График
              </v-btn>
              <v-btn
                elevation="0"
                class="mh-noactive-toggle"
                :active-class="'white black--text mh-active-toggle'"
              >
                <v-icon>mdi-table</v-icon>Таблица
              </v-btn>
            </v-btn-toggle>
          </v-card-title>
          <v-card-text>
            <v-col>
              <v-btn
                @click="saveChartToPng()"
                v-if="visionType === 'graph'"
                class="mb-3"
                >PNG</v-btn
              >
              <div id="highchartDiv">
                <div v-for="chart in chartOptions" :key="chart.id">
                  <highcharts
                    v-if="chart.series[0].data.length && visionType === 'graph'"
                    :options="chart"
                  ></highcharts>
                </div>
              </div>
              <v-btn
                v-if="visionType !== 'graph'"
                @click="copyToClipboard('dt-table')"
                >Копировать в буфер</v-btn
              >
              <v-btn v-if="visionType !== 'graph'" @click="toExcel()"
                >Xlsx</v-btn
              >
              <v-text-field
                v-if="visionType !== 'graph'"
                v-model="tableSearch"
                label="Поиск по таблице"
                class="mx-4"
              ></v-text-field>
              <v-data-table
                id="dt-table"
                v-if="visionType !== 'graph'"
                :headers="tableHeaders"
                :items="tableItemsShow()"
                :hide-default-footer="true"
                multi-sort
                :search="tableSearch"
                disable-pagination
              >
                <template v-slot:item.y="{ item }">
                  <span>{{ item.y.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y1="{ item }">
                  <span>{{ item.y1.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y2="{ item }">
                  <span>{{ item.y2.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y3="{ item }">
                  <span>{{ item.y3.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y4="{ item }">
                  <span>{{ item.y4.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y5="{ item }">
                  <span>{{ item.y5.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.aud="{ item }">
                  <span>{{ item.aud.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.share="{ item }">
                  <span>{{ item.share.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.tvr="{ item }">
                  <span>{{ item.tvr.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.reach="{ item }">
                  <span>{{ item.reach.toString().replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.reachPercent="{ item }">
                  <span>{{
                    item.reachPercent.toString().replace(/\./g, ",")
                  }}</span>
                </template>
              </v-data-table>
            </v-col>
            <v-row justify="center" v-if="chart_loading === true">
              <v-col justify="center"
                ><v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular></v-col
            ></v-row>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="mt-2"
          :height="heightChart"
          v-else-if="chart_loading === true"
        >
          <base-loader></base-loader>
        </v-card>
        <v-row
          v-else
          justify="center"
          class="mt-12"
          style="margin-left: 0; margin-right: 0"
        >
          <v-col class="text-center">
            <svg
              v-if="!isAlternate"
              width="75"
              height="78"
              viewBox="0 0 75 78"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26.6143 37.6417C29.4491 38.6469 30.9569 41.8034 29.9517 44.6583L19.4368 74.2125C18.4316 77.0674 15.2751 78.5551 12.4001 77.5499C9.56532 76.5446 8.07755 73.3882 9.06269 70.5333L19.5776 40.9791C20.5828 38.1443 23.7393 36.6565 26.6143 37.6417Z"
                fill="#DA3A10"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M60.5318 10.52C63.2259 11.9073 64.2713 15.2246 62.8841 17.9186L56.5109 30.2831C55.1236 32.9571 51.7661 34.0226 49.0921 32.6354C46.4182 31.2482 45.3727 27.9309 46.7399 25.2368L53.1131 12.8723C54.5004 10.1983 57.8579 9.15289 60.5318 10.52Z"
                fill="#990099"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M39.5224 0.628454C42.518 0.930028 44.7296 3.64419 44.4079 6.65992L39.1806 57.1433C38.8791 60.1389 36.1448 62.3505 33.1291 62.0288C30.1334 61.7272 27.942 59.0131 28.2436 56.0174L33.4708 5.51394C33.7724 2.51831 36.5067 0.326881 39.5224 0.628454Z"
                fill="#109619"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.7704 38.1243C24.3237 36.4958 27.7415 37.2397 29.37 39.7528L38.3569 53.585C39.9854 56.1383 39.2616 59.536 36.7284 61.1846C34.1751 62.8332 30.7573 62.0893 29.1087 59.5561L20.1419 45.724C18.5134 43.1907 19.2573 39.7729 21.7704 38.1243Z"
                fill="#FF9801"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M36.1255 1.27215C38.719 -0.23572 42.1167 0.628791 43.6447 3.24243L56.3912 25.0361C57.9192 27.6699 57.0346 31.0475 54.4209 32.5755C51.8073 34.1034 48.4096 33.2188 46.9017 30.6052L34.1552 8.79138C32.6473 6.15764 33.5118 2.78001 36.1255 1.27215Z"
                fill="#990099"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M54.4796 11.1435C56.7715 9.21339 60.2497 9.49485 62.1998 11.7868L72.9962 24.4931C74.9463 26.7851 74.6649 30.3034 72.3729 32.2536C70.0609 34.2038 66.5827 33.9223 64.6325 31.6102L53.8362 18.9039C51.9061 16.5919 52.1876 13.0936 54.4796 11.1435Z"
                fill="#0070C0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.23404 52.8408C5.86778 51.3932 9.2253 52.3181 10.7332 54.9518L19.0164 69.8093C20.484 72.4632 19.5391 75.8408 16.9054 77.3085C14.2716 78.7761 10.9141 77.8312 9.40624 75.1974L1.12303 60.3399C-0.324524 57.666 0.600302 54.3085 3.23404 52.8408Z"
                fill="#0070C0"
              />
            </svg>

            <div class="text-md-body-1 body-2 pt-2 mw-295 mr-auto ml-auto">
              Задайте параметры и постройте отчет, он будет отображаться тут
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss"></style>
<script>
import MainFilters from "@/components/MainFilters.vue";
import axios from "axios";
import { Chart } from "highcharts-vue";
import moment from "moment";
import html2canvas from "html2canvas";
import BaseLoader from "@/components/BaseLoader.vue";

export default {
  name: "Reach",
  components: { BaseLoader, MainFilters, highcharts: Chart },
  methods: {
    aggregateItems() {
      let items = [];

      for (let j = 0; j < this.chartOptions.length; j++) {
        let chart = this.chartOptions[j];
        let length = chart.xAxis.categories.length;
        for (let i = 0; i < length; i++) {
          items.push({
            num: j * length + (i + 1),
            title: chart.series[0].data[i].title, //chart.xAxis.categories[i],
            sub: chart.series[0].data[i].sub,
            start: chart.series[0].data[i].start,
            count: chart.series[0].data[i].count,
            aud: chart.series[0].data[i].aud,
            share: chart.series[0].data[i].share,
            tvr: chart.series[0].data[i].tvr,
            y: chart.series[0].data[i].y, //.toString().replace(/\./g, ","),
            y2: chart.series[0].data[i].y2, //.toString().replace(/\./g, ","),
          });
        }
      }
      return items;
    },
    aggregateItemsShow() {
      let items = [];

      for (let j = 0; j < this.chartOptions.length; j++) {
        let chart = this.chartOptions[j];
        let length = chart.xAxis.categories.length;
        for (let i = 0; i < length; i++) {
          items.push({
            num: j * length + (i + 1),
            title: chart.series[0].data[i].title, //chart.xAxis.categories[i],
            sub: chart.series[0].data[i].sub,
            start: chart.series[0].data[i].start,
            count: chart.series[0].data[i].count,
            aud: chart.series[0].data[i].aud.toString().replace(/\./g, ","),
            share: chart.series[0].data[i].share.toString().replace(/\./g, ","),
            tvr: chart.series[0].data[i].tvr.toString().replace(/\./g, ","),
            y: chart.series[0].data[i].y.toString().replace(/\./g, ","),
            y2: chart.series[0].data[i].y2.toString().replace(/\./g, ","),
          });
        }
      }
      return items;
    },
    setCities(cities) {
      this.cities = cities;
    },
    setRange(range) {
      this.range = range;
    },
    async toExcel() {
      let reportName = "report";
      let start = moment(this.range.start).locale("ru").format("DD.MM.YYYY");
      let end = moment(this.range.end).locale("ru").format("DD.MM.YYYY");
      let startTime = moment(this.range.start).locale("ru").format("HH:mm");
      let endTime = moment(this.range.end).locale("ru").format("HH:mm");
      let city = this.cities.find((el) => {
        return el.id === this.selectedCityId;
      });
      let headers = [];

      for (let i = 0; i < this.tableHeaders.length; i++) {
        headers.push({ name: this.tableHeaders[i].text });
      }
      let tableItems = this.tableItems();

      if (this.aggregate === "None") {
        let deleteDate = !(this.report === "hour"
          ? this.aggregate === "Day"
          : false);
        for (let i = 0; i < tableItems.length; i++) {
          if (deleteDate) {
            delete tableItems[i].date;
          }
        }
      } else {
        let noDeleteY2 = this.indicator === "AudienceDemo";
        let noDeleteSub =
          this.aggregate === "ProgramSubprogram" ||
          this.aggregate === "ProgramSubprogramTime";
        let noDeleteStart =
          this.aggregate === "ProgramTime" ||
          this.aggregate === "ProgramSubprogramTime";
        let deleteY = this.indicator === "All";

        for (let i = 0; i < tableItems.length; i++) {
          if (!noDeleteY2) {
            delete tableItems[i].y2;
          }
          if (!noDeleteSub) {
            delete tableItems[i].sub;
          }
          if (!noDeleteStart) {
            delete tableItems[i].start;
          }
          if (deleteY) {
            delete tableItems[i].y;
          } else {
            delete tableItems[i].aud;
            delete tableItems[i].share;
            delete tableItems[i].tvr;
          }
        }
      }

      let req = {
        jsonArray: [[...headers], ...tableItems],
        params: [
          "Время: " + moment().format("DD.MM.YYYY HH:mm:ss"),
          "Регион: " + city.name,
          "Период: " + `${start} ${startTime} - ${end} ${endTime}`,
        ],
        reportName: reportName,
      };
      let response = await axios.post("jsonToExcel", req, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${reportName}.xlsx`);
      document.body.appendChild(link);
      link.click();
    },
    saveChartToPng() {
      html2canvas(document.querySelector(`#highcharts_png`)).then(function (
        canvas
      ) {
        saveAs(canvas.toDataURL(), `chart.png`);
      });
      function saveAs(uri, filename) {
        var link = document.createElement("a");

        if (typeof link.download === "string") {
          link.href = uri;
          link.download = filename;

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
        } else {
          window.open(uri);
        }
      }
    },
    stopCube() {
      this.chart_loading = null;
      this.isStoped = true;
    },
    copyToClipboard(containerid) {
      var range = document.createRange();
      let containerNode = document.getElementById(containerid); //// this part
      range.selectNode(containerNode); //// this part
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      alert("Данные скопированы");
    },
    graphTypeClick(isGraph) {
      this.visionType = isGraph ? "graph" : "table";
    },
    tableItems() {
      let items = [];
      if (this.aggregate !== "None") {
        return this.aggregateItems();
      }
      for (let j = 0; j < this.chartOptions.length; j++) {
        let chart = this.chartOptions[j];
        let length = chart.xAxis.categories.length;

        if (this.indicator === "AudienceDemo") {
          for (let i = 0; i < length; i++) {
            let el = chart.xAxis.categories[i].split(" | ");
            let subtitle = "";
            let title = "";
            if (el.length > 2) {
              title = el[0];
              subtitle = el[1];
              el = el[2].split(" - ");
            } else {
              title = el[0];
              el = el[1].split(" - ");
            }
            let begin = moment(el[0]);
            let end = moment(el[1]);

            items.push({
              num: j * length + (i + 1),
              title: title, //chart.xAxis.categories[i],
              sub: subtitle,
              beginDate: begin.format("DD.MM.YYYY"),
              beginTime: begin.format("HH:mm"),
              endDate: end.format("DD.MM.YYYY"),
              endTime: end.format("HH:mm"),
              y: chart.series[0].data[i].y, //.toString().replace(/\./g, ","),
              y2: chart.series[1].data[i].y, //.toString().replace(/\./g, ","),
            });
          }
        } else {
          if (this.indicator === "All") {
            for (let i = 0; i < length; i++) {
              let el = chart.xAxis.categories[i].split(" | ");
              let subtitle = "";
              let title = "";
              if (el.length > 2) {
                title = el[0];
                subtitle = el[1];
                el = el[2].split(" - ");
              } else {
                title = el[0];
                el = el[1].split(" - ");
              }
              let begin = moment(el[0]);
              let end = moment(el[1]);
              items.push({
                num: j * length + (i + 1),
                title: title, //chart.xAxis.categories[i],
                sub: subtitle,
                beginDate: begin.format("DD.MM.YYYY"),
                beginTime: begin.format("HH:mm"),
                endDate: end.format("DD.MM.YYYY"),
                endTime: end.format("HH:mm"),
                aud: chart.series[0].data[i].aud,
                // .toString()
                // .replace(/\./g, ","),
                share: chart.series[0].data[i].share,
                // .toString()
                // .replace(/\./g, ","),
                tvr: chart.series[0].data[i].tvr,
                // .toString()
                // .replace(/\./g, ","),
              });
            }
          } else {
            for (let i = 0; i < length; i++) {
              let el = chart.xAxis.categories[i].split(" | ");
              let subtitle = "";
              let title = "";
              if (el.length > 2) {
                title = el[0];
                subtitle = el[1];
                el = el[2].split(" - ");
              } else {
                title = el[0];
                el = el[1].split(" - ");
              }
              let begin = moment(el[0]);
              let end = moment(el[1]);
              items.push({
                num: j * length + (i + 1),
                title: title, //chart.xAxis.categories[i],
                sub: subtitle,
                beginDate: begin.format("DD.MM.YYYY"),
                beginTime: begin.format("HH:mm"),
                endDate: end.format("DD.MM.YYYY"),
                endTime: end.format("HH:mm"),
                y: chart.series[0].data[i].y, //.toString().replace(/\./g, ","),
              });
            }
          }
        }
      }
      return items;
    },
    tableItemsShow() {
      let items = [];
      if (this.aggregate !== "None") {
        return this.aggregateItemsShow();
      }
      for (let j = 0; j < this.chartOptions.length; j++) {
        let chart = this.chartOptions[j];
        let length = chart.xAxis.categories.length;

        if (this.indicator === "AudienceDemo") {
          for (let i = 0; i < length; i++) {
            let el = chart.xAxis.categories[i].split(" | ");
            let subtitle = "";
            let title = "";
            if (el.length > 2) {
              title = el[0];
              subtitle = el[1];
              el = el[2].split(" - ");
            } else {
              title = el[0];
              el = el[1].split(" - ");
            }
            let begin = moment(el[0]);
            let end = moment(el[1]);

            items.push({
              num: j * length + (i + 1),
              title: title, //chart.xAxis.categories[i],
              sub: subtitle,
              beginDate: begin.format("DD.MM.YYYY"),
              beginTime: begin.format("HH:mm"),
              endDate: end.format("DD.MM.YYYY"),
              endTime: end.format("HH:mm"),
              y: chart.series[0].data[i].y.toString().replace(/\./g, ","),
              y2: chart.series[1].data[i].y.toString().replace(/\./g, ","),
            });
          }
        } else {
          if (this.indicator === "All") {
            for (let i = 0; i < length; i++) {
              let el = chart.xAxis.categories[i].split(" | ");
              let subtitle = "";
              let title = "";
              if (el.length > 2) {
                title = el[0];
                subtitle = el[1];
                el = el[2].split(" - ");
              } else {
                title = el[0];
                el = el[1].split(" - ");
              }
              let begin = moment(el[0]);
              let end = moment(el[1]);
              items.push({
                num: j * length + (i + 1),
                title: title, //chart.xAxis.categories[i],
                sub: subtitle,
                beginDate: begin.format("DD.MM.YYYY"),
                beginTime: begin.format("HH:mm"),
                endDate: end.format("DD.MM.YYYY"),
                endTime: end.format("HH:mm"),
                aud: chart.series[0].data[i].aud.toString().replace(/\./g, ","),
                share: chart.series[0].data[i].share
                  .toString()
                  .replace(/\./g, ","),
                tvr: chart.series[0].data[i].tvr.toString().replace(/\./g, ","),
              });
            }
          } else {
            for (let i = 0; i < length; i++) {
              let el = chart.xAxis.categories[i].split(" | ");
              let subtitle = "";
              let title = "";
              if (el.length > 2) {
                title = el[0];
                subtitle = el[1];
                el = el[2].split(" - ");
              } else {
                title = el[0];
                el = el[1].split(" - ");
              }
              let begin = moment(el[0]);
              let end = moment(el[1]);
              items.push({
                num: j * length + (i + 1),
                title: title, //chart.xAxis.categories[i],
                sub: subtitle,
                beginDate: begin.format("DD.MM.YYYY"),
                beginTime: begin.format("HH:mm"),
                endDate: end.format("DD.MM.YYYY"),
                endTime: end.format("HH:mm"),
                y: chart.series[0].data[i].y.toString().replace(/\./g, ","),
              });
            }
          }
        }
      }
      return items;
    },

    async cubeData(req) {
      this.filter_loading = true;
      this.chart_loading = true;
      this.isStoped = false;
      //TODO replace api url
      let cubeObj = {};
      req.forEach(function (value, key) {
        cubeObj[key] = value;
      });
      cubeObj["email"] = this.$store.getters.StateUser.email;

      cubeObj["action"] = "MakeReport";
      // eslint-disable-next-line no-undef
      let reportUniqid = Utils.generateUID();
      cubeObj["uniqid"] = reportUniqid;
      cubeObj["uid"] = this.$store.getters.StateUser.uid;
      cubeObj["ym_uid"] = this.check_cookie_name("_ym_uid");
      // eslint-disable-next-line no-undef
      if (process.env.VUE_APP_ALTERNATE_UI != "true") mh_px(300, cubeObj);
      // eslint-disable-next-line no-unused-vars
      let timeReq = performance.now();
      let res = await axios.post("reports/cubedata", req).catch(function () {
        this.filter_loading = false;
        this.chart_loading = null;
        alert("Произошла ошибка");
        return;
      });
      res;
      if (!this.isStoped) {
        this.filter_loading = false;
        this.selectedCityId = req.get("geo");
        this.report = req.get("report_type");
        this.aggregate = req.get("aggregate");
        // if (this.aggregate !== "None") {
        //   this.toggle_graphType = 1;
        //   let programs = [];
        //   let categories = [];
        //   let programmsIds = [];
        //   for (let i = 0; i < res.data.charts[0].series[0].data.length; i++) {
        //     let prog_id = res.data.charts[0].series[0].data[i].prog.program_id;
        //     let prog_title = res.data.charts[0].series[0].data[i].prog.title;
        //
        //     switch (this.aggregate) {
        //       case "ProgramSubprogram":
        //         prog_id +=
        //           "_" + res.data.charts[0].series[0].data[i].prog.subtitle ?? 0;
        //
        //         break;
        //       case "ProgramTime":
        //         prog_id +=
        //           "_" +
        //             res.data.charts[0].series[0].data[i].prog.start_loc_time.replace(/:/g, "") ??
        //           0;
        //         break;
        //       case "ProgramSubprogramTime":
        //         prog_id +=
        //           "_" + res.data.charts[0].series[0].data[i].prog.subtitle ??
        //           0 +
        //             "_" +
        //             res.data.charts[0].series[0].data[i].prog.start_loc_time.replace(/:/g, "")  ??
        //           0;
        //         break;
        //     }
        //
        //     if (programs[prog_id]) {
        //       programs[prog_id].count++;
        //       programs[prog_id].y +=
        //         res.data.charts[0].series[0].data[i].y ?? 0;
        //       programs[prog_id].y2 += res.data.charts[0].series[1]
        //         ? res.data.charts[0].series[1].data[i].y
        //         : 0;
        //
        //       programs[prog_id].aud +=
        //         res.data.charts[0].series[0].data[i].aud ?? 0;
        //       programs[prog_id].tvr +=
        //         res.data.charts[0].series[0].data[i].tvr ?? 0;
        //       programs[prog_id].share +=
        //         res.data.charts[0].series[0].data[i].share ?? 0;
        //     } else {
        //       programmsIds.push(prog_id);
        //       categories.push(`${prog_title} |  | `);
        //       programs[prog_id] = {
        //         y: res.data.charts[0].series[0].data[i].y,
        //         y2: res.data.charts[0].series[1]
        //           ? res.data.charts[0].series[1].data[i].y
        //           : 0,
        //         aud: res.data.charts[0].series[0].data[i].aud ?? 0,
        //         tvr: res.data.charts[0].series[0].data[i].tvr ?? 0,
        //         share: res.data.charts[0].series[0].data[i].share ?? 0,
        //         title: res.data.charts[0].series[0].data[i].prog.title ?? "",
        //         sub: res.data.charts[0].series[0].data[i].prog.subtitle ?? "",
        //         start: res.data.charts[0].series[0].data[i].prog.start_loc_time,
        //         count: 1,
        //       };
        //     }
        //   }
        //   let clearProgs = [];
        //
        //   for (let ii = 0; ii < programmsIds.length; ii++) {
        //     programs[programmsIds[ii]].y =
        //       Math.round(
        //         (programs[programmsIds[ii]].y /
        //           programs[programmsIds[ii]].count) *
        //           1000
        //       ) / 1000;
        //     programs[programmsIds[ii]].y2 =
        //       Math.round(
        //         (programs[programmsIds[ii]].y2 /
        //           programs[programmsIds[ii]].count) *
        //           1000
        //       ) / 1000;
        //     programs[programmsIds[ii]].aud =
        //       Math.round(
        //         (programs[programmsIds[ii]].aud /
        //           programs[programmsIds[ii]].count) *
        //           1000
        //       ) / 1000;
        //     programs[programmsIds[ii]].tvr =
        //       Math.round(
        //         (programs[programmsIds[ii]].tvr /
        //           programs[programmsIds[ii]].count) *
        //           1000
        //       ) / 1000;
        //     programs[programmsIds[ii]].share =
        //       Math.round(
        //         (programs[programmsIds[ii]].share /
        //           programs[programmsIds[ii]].count) *
        //           1000
        //       ) / 1000;
        //     clearProgs.push(programs[programmsIds[ii]]);
        //   }
        //   res.data.charts[0].series[0].data = clearProgs;
        //   res.data.charts[0].categories = categories;
        // }
      }
      this.chart_loading = false;
      timeReq = performance.now() - timeReq;
      if (process.env.VUE_APP_ALTERNATE_UI != "true")
        // eslint-disable-next-line no-undef
        mh_px(300, {
          ym_uid: this.check_cookie_name("_ym_uid"),
          uniqid: reportUniqid,
          action: "DataReport",
          email: this.$store.getters.StateUser.email,
          uid: this.$store.getters.StateUser.uid,
          msecs: timeReq,
        });

      this.indicator = req.get("indicator");
      //  this.data = res.data;
      //this.data.load = true;
    },
  },
  data: () => ({
    range: {},
    cities: [],
    aggregate: "None",
    toggle_graphType: 0,
    report: null,
    selectedCityId: null,
    tableSearch: null,
    isStoped: false,
    visionType: "graph",
    filter_loading: false,
    data: {},
    indicator: "",
    chart_loading: null,
    pageData: {
      title: "Накопленный охват",
      type: "show",
      many_channel: false,
    },
  }),
  computed: {
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    tableHeaders() {
      if (this.aggregate !== "None") {
        return this.tableHeadersAgregate;
      }
      let headers = [
        {
          text: "№",
          value: "num",
          visible: true,
        },
        {
          text: "Передача",
          value: "title",
          visible: true,
        },
        {
          text: "Серия",
          value: "sub",
          visible: true,
        },
        {
          text: "Начало, дата",
          value: "beginDate",
          visible: true,
        },
        {
          text: "Начало, время",
          value: "beginTime",
          visible: true,
        },
        {
          text: "Окончание, дата",
          value: "endDate",
          visible: true,
        },
        {
          text: "Окончание, время",
          value: "endTime",
          visible: true,
        },
        {
          text: this.chartTittle,
          value: "y",
          visible: true,
        },
      ];

      if (this.indicator === "All") {
        return [
          {
            text: "№",
            value: "num",
            visible: true,
          },
          {
            text: "Передача",
            value: "title",
            visible: true,
          },
          {
            text: "Серия",
            value: "sub",
            visible: true,
          },
          {
            text: "Начало, дата",
            value: "beginDate",
            visible: true,
          },
          {
            text: "Начало, время",
            value: "beginTime",
            visible: true,
          },
          {
            text: "Окончание, дата",
            value: "endDate",
            visible: true,
          },
          {
            text: "Окончание, время",
            value: "endTime",
            visible: true,
          },
          {
            text: this.chartTittle,
            value: "aud",
            visible: true,
          },
          {
            text: "Доля, %",
            value: "share",
            visible: true,
          },
          {
            text: "Рейтинг",
            value: "tvr",
            visible: true,
          },
        ];
      }

      if (this.indicator === "AudienceDemo") {
        headers[headers.length - 1]["text"] = "Мужчины";
        headers.push({
          text: "Женщины",
          value: "y2",
          visible: true,
        });
      }
      return headers.filter((el) => {
        return el.visible;
      });
    },

    tableHeadersAgregate() {
      let headers = [
        {
          text: "№",
          value: "num",
          visible: true,
        },
        {
          text: "Передача",
          value: "title",
          visible: true,
        },
        {
          text: "Серия",
          value: "sub",
          visible:
            this.aggregate === "ProgramSubprogram" ||
            this.aggregate === "ProgramSubprogramTime",
        },
        {
          text: "Время выхода",
          value: "start",
          visible:
            this.aggregate === "ProgramTime" ||
            this.aggregate === "ProgramSubprogramTime",
        },
        {
          text: "Количество выходов",
          value: "count",
          visible: true,
        },
        {
          text: this.chartTittle,
          value: "y",
          visible: true,
        },
      ];
      if (this.indicator === "All") {
        return [
          {
            text: "№",
            value: "num",
            visible: true,
          },
          {
            text: "Передача",
            value: "title",
            visible: true,
          },
          {
            text: "Серия",
            value: "sub",
            visible:
              this.aggregate === "ProgramSubprogram" ||
              this.aggregate === "ProgramSubprogramTime",
          },
          {
            text: "Время выхода",
            value: "start",
            visible:
              this.aggregate === "ProgramTime" ||
              this.aggregate === "ProgramSubprogramTime",
          },
          {
            text: "Количество выходов",
            value: "count",
            visible: true,
          },
          {
            text: this.chartTittle,
            value: "aud",
            visible: true,
          },
          {
            text: "Доля, %",
            value: "share",
            visible: true,
          },
          {
            text: "Рейтинг",
            value: "tvr",
            visible: true,
          },
        ];
      }
      if (this.indicator === "AudienceDemo") {
        headers[headers.length - 1]["text"] = "Мужчины";
        headers.push({
          text: "Женщины",
          value: "y2",
          visible: true,
        });
      }

      return headers.filter((el) => {
        return el.visible;
      });
    },
    heightChart() {
      let h = 800;
      // if (this.report !== "hour") {
      //   h =
      //     this.data.charts &&
      //     this.data.charts[0] &&
      //     this.data.charts[0].categories.length;
      //   h = h * 40 + 25;
      //   if (this.indicator === "AudienceDemo") {
      //     h = h * 2;
      //   }
      //   h = h < 400 ? 400 : h;
      // }
      return h;
    },
    chartOptions() {
      let charts = [];
      let data = this.data.charts ?? [];
      for (let ii = 0; ii < data.length; ii++) {
        let categories = data[ii].categories;
        for (let j = 0; j < categories.length; j++) {
          categories[j] = categories[j].replace(" |  | ", " | ");
        }
        charts.push({
          id: ii,
          chart: {
            type: this.report === "hour" ? "spline" : "column",
            events: {},
            height: this.heightChart,
          },
          title: { text: "" },
          colors:
            this.indicator === "AudienceDemo"
              ? [
                  "#00BFA5",
                  "#E91E63",
                  "#3366cc",
                  "#dc3912",
                  "#ff9900",
                  "#109618",
                  "#990099",
                ]
              : ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099"],
          subtitle: { text: data[ii].series[0].subtitle ?? "" },
          exporting: {
            sourceWidth: 1200,
            chartOptions: {
              chart: {
                events: {},
              },
            },
            // buttons: {
            //   contextButton: {
            //     menuItems: buttons,
            //   },
            // },
          },
          xAxis: {
            // tickInterval: 3600 * 1000,
            categories: categories,
            type: "datetime",
            labels: {
              //format: "{value:%m.%d.%Y %H:%M}",
            },
            // labels: {
            //   formatter: function () {
            //     // var startTime = data.categories[0].substr(0, 16) + ":00";
            //     // var time = moment(startTime);
            //     // time.add(parseInt(this.value / 1000), "seconds");
            //     // var hhmm = time.format("HH:mm");
            //     //
            //     // if (hhmm == "00:00" || this.value == 0) {
            //     //   return time.format("DD.MM.YY HH:mm");
            //     // } else {
            //     //   return time.format("HH:mm");
            //     // }
            //     return Chart.dateFormat("%m.%d.%Y %H:%M", this.value);
            //   },
            // },
          },
          yAxis: {
            reversedStacks: false,
            title: { text: "" },
            plotLines: [
              {
                value: 0,
                width: 1,
                color: "#808080",
              },
            ],
          },
          plotOptions: this.plotOpts,
          // tooltip: {
          //   formatter: function () {
          //     var startTime = data.categories[0].substr(0, 16) + ":00";
          //
          //     var time = moment(startTime);
          //     time.add(parseInt(this.key / 1000), "seconds");
          //
          //     var date =
          //       displayDays == 0
          //         ? texts.tvchannel_tvindicators_hour_detalisation_table_airtime +
          //           ": " +
          //           (parseInt(this.key / 1000 / 3600) + 1) +
          //           "<br>"
          //         : time.format("DD.MM.YYYY");
          //
          //     var h = time.format("HH");
          //     var m = time.format("mm");
          //
          //     var nextMin = nextMinutesInterval(h, m);
          //
          //     var val = formatVal(this.y, activePage);
          //
          //     return (
          //       "<small>" +
          //       "<br>" +
          //       date +
          //       " " +
          //       h +
          //       ":" +
          //       m +
          //       nextMin +
          //       '</small><br/><span style="color:' +
          //       this.color +
          //       '">\u25CF</span> ' +
          //       (tvname ? tvname + " - " : "") +
          //       this.series.name +
          //       "<br />" +
          //       rowHeader +
          //       ": <b>" +
          //       val +
          //       " " +
          //       rowSuffix +
          //       "</b>"
          //     );
          //   },
          // },
          credits: { enabled: false },
          series: data[ii].series,
        });
      }
      return charts;
    },
    plotOpts() {
      if (this.report === "hour") {
        return {
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
            events: {
              // click: function (event) {
              //   if (!data.programs) return;
              //   var channelId = event.point.options.id;
              //   var category =
              //     (intervalDiv * event.point.category) / 3600 / 1000;
              //   $(".tv_accent_" + id)
              //     .val(channelId)
              //     .trigger("change");
              //
              //   videoChannel = getVideoChannel(channelId);
              //
              //   function findProgByTime(videoChannel, dateTime) {
              //     if (
              //       typeof data.programs === "undefined" ||
              //       typeof data.programs[videoChannel] === "undefined"
              //     )
              //       return { prog_hash: 0, title_lk: "" };
              //
              //     var progs = data.programs[videoChannel].filter(function (
              //       item
              //     ) {
              //       return dateTime >= item.start && dateTime <= item.finish;
              //     });
              //
              //     if (progs.length) {
              //       return progs[0];
              //     }
              //
              //     return { prog_hash: undefined, title_lk: "" };
              //   }
              //
              //   var prog = findProgByTime(channelId, data.categories[category]);
              //
              //   playPoint2019(
              //     videoChannel,
              //     data.categories[category],
              //     prog.prog_hash,
              //     prog.title_lk
              //   );
              //
              //   $("#aboutProgram").insertAfter(".accentBlock_" + id);
              // },
            },
            turboThreshold: 3600 * 24,
            marker: {
              radius: 2,
            },
          },
        };
      } else {
        return {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
            stacking: this.indicator === "AudienceDemo" ? false : "normal",
            dataLabels: { enabled: false },
            pointWidth: 20,
            //   events: {
            //   afterAnimate: function() {
            //     this.chart.axes[1].stackTotalGroup.show();
            //   },
            //   // click: function (event) {
            //   //   var cid = event.point.series.userOptions.id;
            //   //   var date = event.point.category;
            //   //   if (reportInside==2) dropToHours(channelsData[cid].id, date)
            //   // }
            // }
          },
        };
      }
    },
    chartTittle() {
      let title = "Показатель";
      switch (this.indicator) {
        case "Watchtime":
          title = "Время просмотра, с";
          break;
        case "UuidCount":
          title = "Уникальные устройства за час";
          break;
        case "SanCount":
          title = "Уникальные домохозяйства за час";
          break;
        case "UuidCountDay":
          title = "Уникальные устройства за сутки";
          break;
        case "SanCountDay":
          title = "Уникальные домохозяйства за сутки";
          break;
        case "Audience":
          title = "Аудитория, тыс.";
          break;
        case "AudienceDemo":
          title = "Аудитория, тыс.";
          break;
        case "TVR":
          title = "Рейтинг";
          break;
        case "Share":
          title = "Доля, %";
          break;
        case "All":
          title = "Аудитория, тыс.";
          break;
      }
      return title;
    },
  },
  watch: {
    indicator() {
      if (this.indicator === "All") {
        this.toggle_graphType = 1;
      }
    },
    toggle_graphType() {
      this.visionType = this.toggle_graphType === 0 ? "graph" : "table";
    },
  },
};
</script>
